<template>
  <div class="announce">
    <h2>{{ detail.Title }}</h2>
    <div class="date">{{ detail.AddDTimeFormat }}</div>
    <div class="desc" v-html="detail.Content"></div>
  </div>
</template>

<script>
import { closeToast, showLoadingToast, showToast } from "vant";
export default {
  data() {
    return {
      aid: 0,
      detail: {},
    };
  },
  created() {
    this.SYSLANG = this.LANG.system;

    if (!this.$route.query.id || isNaN(this.$route.query.id)) {
      this.$router.back();
      return;
    } else {
      this.aid = this.$route.query.id;
    }

    this.init();
  },
  methods: {
    init() {
      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });

      this.axios
        .get(this.actions.announcedetail + this.aid)
        .then(({ data }) => {
          closeToast();
          let { code, msg, detail } = data;
          if (code == "SUCCESS") {
            this.detail = detail;
          } else {
            showToast({
              message: this.SYSLANG[msg],
              forbidClick: true,
              onClose: () => {
                if (code == "NOTLOGIN") {
                  this.$router.replace({
                    path: "/login",
                  });
                }
              },
            });
          }
        })
        .catch((error) => {
          console.log("views.Announce.init.error", error);
          closeToast();
          this.init();
        });
    },
  },
};
</script>

<style scoped>
.announce {
  padding: 16px;
  color: #000;
}
.announce h2 {
  line-height: 36px;
  font-size: 16px;
  margin: 0;
}

.announce .date {
  line-height: 20px;
  font-size: 12px;
  color: #555;
}

.announce .desc {
  margin-top: 10px;
  line-height: 22px;
  font-size: 14px;
}

@media (min-width: 679px) {
  .announce {
    padding: calc(100vw / 1920 * 60) calc(100vw / 1920 * 360)
      calc(100vw / 1920 * 70) calc(100vw / 1920 * 360);
    background-color: #f7f7f7;
  }
}
</style>